import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import TriangleBlogPost from "../style/Header/triangleBlogPost";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Article } from "../style/Article";
import { Flex, Wrapper } from "../style/Grid";
import TileButton from "../components/TileButton";
import { URL } from "../constants";
import Button from "../components/Button";
import EventContent from "../components/Card/EventContent";
import { ShareSocial } from "../components/ShareSocial";
import usePageView from "../utils/usePageView";
import Container from "../style/Grid/Container";

const EventPostTemplate = props => {
  usePageView(props.location.pathname, "Potkej se s námi", "Akce");

  const post = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;
  const location = props.location;
  const postData = post.frontmatter;

  require("moment/locale/cs");


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={postData.metaTitle || postData.title}
        description={
          postData.metaDescription || postData.description || post.excerpt
        }
        ogimage={postData.jobHeaderImageFile ? postData.jobHeaderImageFile.publicURL : null}
      />
      <Header
        bgImage={postData.jobHeaderImageFile}
        minHeight={["500px", null, "611px", null, null]}
        bottomComponents={<TriangleBlogPost />}
        containerJustifyContent={"flex-end"}
        shadowAfter={true}
      >
        <Heading
          as="h1"
          color="white.100"
          mb={[3, 3, 4, null, null]}
          fontSize={[5, null, 5, 7, null]}
          fontWeight="light"
          lineHeight="2"
          maxWidth={["100%", null, "1040px", null, null]}
          backgroundPosition="center center"
        >
          {postData.title}
        </Heading>
        <EventContent
          color="white.100"
          fontSize="2"
          event={postData}
          eventPost={true}
        />
        <Button
          href={`${postData.link}`}
          mt={[3, 4, null, null, "45px"]}
          mb={[4, 5, null, null, 6]}
          onClick={() =>
            window.dataLayer.push({
              event: "form.sent",
              form: `Přihlášení na akci ${postData.title}`,
            })
          }
        >
          Tam chci
        </Button>
      </Header>
      <Wrapper mb="20px">
        <Container>
          <Article width={["100%", "100%", null, null, "90%"]}>
            <MDXRenderer>{post.body}</MDXRenderer>
            <ShareSocial url={location.href} />
          </Article>
        </Container>
      </Wrapper>

      <section>
        <Flex
          justifyContent="flex-end"
          flexDirection={["column-reverse", "row", "row", "row", null]}
        >
          <TileButton
            to={URL.events}
            width={["100%", "50%", null, null, null]}
            tileColor="dark"
            tileOrientation="left"
          >
            Chci si vybrat
            <br />
            jinou akci
          </TileButton>
          <TileButton
            href={`${postData.link}`}
            width={["100%", "50%", null, null, null]}
            tileColor="light"
            tileOrientation="right"
            as={"a"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              window.dataLayer.push({
                event: "form.sent",
                form: `Přihlášení na akci ${postData.title}`,
              })
            }
          >
            <Flex flexDirection="column" alignItems="flex-end">
              <Text
                fontSize={["18px", null, "5", null, null]}
                color="EYOffBlack.tile"
              >
                Jdu tam
              </Text>
              <Text
                fontSize={[1, null, 2, null, null]}
                fontWeight="300"
                color="EYOffBlack.tile"
                opacity=".7"
                mt={["8px", null, "24px", null, null]}
              >
                Zarezervuj si místo na akci
              </Text>
            </Flex>
          </TileButton>
        </Flex>
      </section>
    </Layout>
  );
};

export default EventPostTemplate;

export const pageQuery = graphql`
  query EventPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { templateKey: { eq: "event-post" }}) {
      id
      body
      frontmatter {
        title
        metaTitle
        metaDescription
        jobHeaderImageFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        dateFrom(formatString: "YYYY-MM-DD, HH:mm Z")
        dateTo(formatString: "YYYY-MM-DD, HH:mm Z")
        link
        place
      }
    }
  }
`;
